import './Header.css';

import HistoryIcon from '@mui/icons-material/History';
import { AppBar, Container, Fab, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import TicketHistory from './TicketHistory';

export default function TpvHeader() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <AppBar position="static" elevation={0} color='primary' style={{ marginBottom: 10, paddingBottom: 10 }}>
            <Container maxWidth="xl">
                <Toolbar>
                    <img alt='Logo Tropical' src='/img/logo.png' className='logo' style={{ width: 80 }} />
                    <Typography variant='h2' style={{ margin: 'auto' }}>TPV Pedidos</Typography>
                    <Fab onClick={() => setOpen(true)}>
                        <HistoryIcon />
                    </Fab>
                    <TicketHistory open={open} onClose={handleClose} />
                </Toolbar>
            </Container>
        </AppBar>
    )
}