import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Button, CircularProgress, Divider, Fade, IconButton, Slide, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TransitionGroup } from 'react-transition-group';
import { UserContext } from "../context/Context";
import { recoveryPassword } from "../service/auth.service";
import { FRONT_URLS } from "../config/url.config";

export default function Login({ handleClose }) {
    const [showPassword, setShowPassword] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [loginHidded, setLoginHidded] = useState(false);

    const { login } = useContext(UserContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [recoveryMsg, setRecoveryMsg] = useState(false);
    const [loginCounter, setLoginCounter] = useState(1);

    const signin = async () => {
        setLoading(true);
        setError(null);
        if (username === "" || password === "") {
            setError("Tiene que rellenar los campos");
            setLoading(false);
            return;
        }
        const logged = await login(username, password);
        setLoading(false);
        if (!logged) {
            setLoginCounter(loginCounter + 1);
            setError("Usuario o contraseña incorrectos" + (loginCounter >= 5 ? ". Es posible que su usuario no esté activado. Al registrarse se envia un correo para su activación. Revise su correo o haga click he olvidado la contraseña." : ""));
            return;
        }
        setLoginCounter(1);
        handleClose();
    }

    useEffect(() => {
        setError(null);
        setRecoveryMsg(false);
    }, [showLogin]);

    const recoveryPass = () => {
        if (username === "") {
            setError("Tiene que rellenar el correo");
            return;
        }
        recoveryPassword(username);
        setError(null);
        setRecoveryMsg(true);
    }

    return (
        <Stack spacing={2}>
            <TransitionGroup>
                {
                    showLogin && !loginHidded &&
                    <Slide direction={'right'} timeout={500} onExited={() => setLoginHidded(true)}>
                        <Stack spacing={2}>
                            {error && (<Alert severity="error">{error}</Alert>)}
                            <TextField
                                label="Correo electrónico"
                                variant="outlined"
                                value={username}
                                onChange={(e) => setUsername(e.currentTarget.value)}
                                error={error && !username}
                            />
                            <TextField label="Contraseña" variant="outlined" type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.currentTarget.value)}
                                error={error && !password}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={() => setShowPassword(true)}
                                            onMouseUp={() => setShowPassword(false)}
                                            edge="end"
                                            tabIndex={-1}
                                        >
                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    ),
                                }}
                            />
                            <Button onClick={signin} style={{ textTransform: 'none' }} variant="contained" size="large" disabled={loading}>
                                Iniciar sesión
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Button>
                        </Stack>
                    </Slide>
                }
                {
                    !showLogin && loginHidded &&
                    <Slide direction={'left'} timeout={500} onExited={() => setLoginHidded(false)}>
                        <Stack spacing={2}>
                            <Typography variant="body2">
                                Introduzca su mail y le enviamos un nuevo acceso
                            </Typography>
                            {error && (<Alert severity="error">{error}</Alert>)}
                            <TextField label="Correo electrónico" variant="outlined" value={username} onChange={(e) => setUsername(e.currentTarget.value)} />
                            {recoveryMsg && (<Alert>Si el usuario existe, en breve recibirá un correo para cambiar su contraseña</Alert>)}
                            {!recoveryMsg && (<Button onClick={recoveryPass} style={{ textTransform: 'none' }} variant="contained" size="large">Enviar</Button>)}
                        </Stack>
                    </Slide>
                }
            </TransitionGroup>
            <Divider></Divider>
            {
                showLogin &&
                <Fade in={showLogin} timeout={1000}>
                    <Typography variant="body2" textAlign="center" style={{ cursor: 'pointer' }} onClick={() => { setShowLogin(!showLogin) }}>
                        ¿Has olvidado la contraseña?
                    </Typography>
                </Fade>
            }
            {
                !showLogin &&
                <Fade in={!showLogin} timeout={1000}>
                    <Typography variant="body2" textAlign="center" style={{ cursor: 'pointer' }} onClick={() => { setShowLogin(!showLogin) }}>
                        Volver a inicio de sesión
                    </Typography>
                </Fade>
            }
            <Typography variant="body2">
                ¿No estás registrado? <Button component={Link} to={`/${FRONT_URLS.signup}`} onClick={handleClose} color="secondary" variant="outlined" style={{ textTransform: 'none' }}>Regístrate ahora</Button>
            </Typography>
        </Stack>
    )
}