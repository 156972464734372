import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Stack, TextField, Zoom } from "@mui/material";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { TicketContext } from '../context/Context';
import NumberInput from "./NumberInput";

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});

const FreeItemDetail = forwardRef((props, ref) => {
    const { edit } = props;
    const ticketContext = useContext(TicketContext);
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [name, setName] = useState('');
    const [price, setPrice] = useState(null);

    useImperativeHandle(ref, () => ({
        openDetails(i) {
            setQuantity(i.quantity ?? 1);
            setName(i.name ?? '');
            setPrice(i.price ?? '');

            setOpen(true);
        }
    }));

    const closeDetails = () => {
        setOpen(false);
    }

    const addItem = () => {
        if (!name || !price) return;
        const item = {
            id: 0,
            quantity,
            name,
            price,
            extras: []
        }

        ticketContext.addItem(item, edit);
        closeDetails();
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            onClose={closeDetails}
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                Texto libre
                <IconButton
                    onClick={closeDetails}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <TextField
                        style={{ marginBlock: 5 }}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                    />
                    <TextField
                        style={{ marginBlock: 5 }}
                        label="Precio"
                        variant="outlined"
                        type='number'
                        value={price}
                        onChange={(e) => setPrice(e.currentTarget.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack width="100%" spacing={{ xs: 1 }} direction="row" useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <NumberInput value={quantity} onChange={(event, val) => setQuantity(val)} min={1} max={99} readOnly />
                    <Button
                        variant='contained'
                        color='primary'
                        style={{

                        }}
                        onClick={addItem}
                    >
                        {edit !== undefined ? 'Guardar' : 'Añadir'}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog >
    );
});

export default FreeItemDetail;