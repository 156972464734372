import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getStreets } from "../service/store.service";

export default function StreetInput(props) {
    const { value, setValue } = props;

    let inputProps = {};
    Object.keys({ ...props }).map(d => {
        if (d !== 'value' && d !== 'setValue') inputProps[d] = props[d];
        return true;
    });

    const [streets, setStreets] = useState([]);

    useEffect(() => {
        getStreets()
            .then((s) => setStreets(s))
            .catch((e) => {
                console.log('streets error', e);
            });
    }, []);

    return (
        <Autocomplete
            disableClearable
            openOnFocus={false}
            autoHighlight
            openText='Mostrar opciones'
            noOptionsText='No se encuentra la calle'
            options={streets}
            value={value}
            onChange={(e, pValue) => setValue(pValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Calle'
                    name='street'
                    type='text'
                    size="medium"
                    variant="outlined"
                    fullWidth
                    required
                    //helperText="Empiece a escribir y seleccione su calle de la lista"
                    {...inputProps}
                />
            )}
        />
    );
}