import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FRONT_URLS } from "../config/url.config";
import { TicketContext } from "../context/Context";
import { Box, Typography } from "@mui/material";
import './Animations.css';

export default function Confirmation({ confirmed }) {

    const navigate = useNavigate();
    const ticketContext = useContext(TicketContext);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (!confirmed) {
            navigate(`/${FRONT_URLS.orders}`, { state: { error: true }, replace: true });
        } else {
            ticketContext.resetTicket();
        }
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return confirmed && (
        <Box position="relative">
            <div className="success-checkmark">
                <div className={`check-icon ${animate ? " animate" : ''}`}>
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <Typography variant="h6">
                El pago de su pedido se ha procesado correctamente. En breve recibirá un mail con la confirmación del pedido.
                <br></br>
                Si está registrado, puede consultar el estado de sus pedidos realizados en el apartado Mis pedidos.
            </Typography>
        </Box>
    );
}