import './Header.css';

import CloseIcon from '@mui/icons-material/Close';
import DiscountIcon from '@mui/icons-material/Discount';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/PersonRounded';
import ReceiptIcon from '@mui/icons-material/ReceiptRounded';
import { AppBar, Avatar, Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, Drawer, IconButton, ListItemIcon, Menu, MenuItem, Stack, Toolbar, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FRONT_URLS } from '../config/url.config';
import { UserContext } from '../context/Context';
import menu from '../routes/menu';
import Login from './Login';

const color = 'secondary';

const MenuButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: theme.palette[color].main,
    '&:hover': {
        backgroundColor: theme.palette[color].dark,
    },
    '&.active': {
        backgroundColor: theme.palette[color].light,
    },
    padding: '20px',
    borderRadius: 10
}));

const UserButton = styled(Button)(({ theme }) => ({
    color: 'black',
    backgroundColor: '#e0e0e0',
    '&:hover': {
        backgroundColor: '#f5f5f5',
    },
    padding: '8px 25px',
    borderRadius: 50,
    fontSize: '14px',
    textTransform: 'none'
}));

const Profile = ({ user, showMenu, openLogin }) => {
    if (user) {
        return (
            <IconButton sx={{ p: 0, display: 'block', marginInline: { xs: 'auto', md: 0 }, marginBlock: { xs: 2, md: 0 } }} onClick={showMenu}>
                <Avatar alt={user.name} />
            </IconButton >
        )
    } else {
        return (
            <UserButton sx={{ marginInline: 'auto', marginBlock: { xs: 2, md: 0 }, padding: { xs: '15px 80px', md: '8px 30px' } }} onClick={() => openLogin(true)}>Iniciar sesión</UserButton>
        )
    }
}

const LoginDialog = ({ open, handleClose }) => {
    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
            <DialogTitle>Acceso usuarios</DialogTitle>
            <DialogContent dividers>
                <Login handleClose={handleClose}></Login>
            </DialogContent>
        </Dialog>
    )
}

const LinksMenu = ({ menu }) => {
    return menu.map((pMenu) => (
        <NavLink to={pMenu.path} key={pMenu.path}>
            {({ isActive, isPending, isTransitioning }) => (
                <MenuButton
                    className={isActive ? 'active' : ''}
                    sx={{ width: { xs: '80%', md: 'auto' } }}
                >
                    {pMenu.title}
                </MenuButton>
            )}
        </NavLink>
    ));
}

const ProfileMenu = ({ logout }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const iconStyle = {
        color: { xs: theme.palette.primary.contrastText, md: theme.palette.text.secondary },
    };
    const textStyle = {
        ...iconStyle,
        marginInline: { xs: '20px', md: 0 },
    };
    const activeStyle = ({ isActive, isPending, isTransitioning }) => {
        if (isActive) return {
            borderRadius: mobile ? '10px' : '',
            backgroundColor: mobile ? theme.palette.secondary.light : theme.palette.grey[300],
        }
    };

    return (
        <Box>
            <MenuItem component={NavLink} to={FRONT_URLS.profile} sx={textStyle} style={activeStyle}>
                <ListItemIcon sx={iconStyle}>
                    <PersonIcon />
                </ListItemIcon>
                Mi perfil
            </MenuItem>
            <MenuItem component={NavLink} to={FRONT_URLS.myorders} sx={textStyle} style={activeStyle}>
                <ListItemIcon sx={iconStyle}>
                    <ReceiptIcon />
                </ListItemIcon>
                Mis pedidos
            </MenuItem>
            <MenuItem component={NavLink} to={FRONT_URLS.vouchers} sx={textStyle} style={activeStyle}>
                <ListItemIcon sx={iconStyle}>
                    <DiscountIcon />
                </ListItemIcon>
                Mis bonos
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout} sx={{ color: theme.palette.error.main, mx: { xs: 3, md: 0 }, my: { xs: 1, md: 0 } }}>
                <ListItemIcon sx={{ color: theme.palette.error.main }}>
                    <LogoutIcon />
                </ListItemIcon>
                Cerrar sesión
            </MenuItem>
        </Box>
    );
}

export default function Header() {
    const theme = useTheme();
    const [menuState, setMenuState] = useState(false);
    const [loginOpened, setLoginOpened] = useState(false);
    const { user, logout } = useContext(UserContext);
    const [anchor, setAnchor] = useState(null);
    const showProfileMenu = Boolean(anchor);

    const profileMenuClick = (e) => {
        setAnchor(e.currentTarget)
    }

    const closeProfileMenu = () => {
        setAnchor(false);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMenuState(open);
    };

    return (
        <AppBar position="static" elevation={0} color='transparent'
            sx={{ marginBottom: { xs: '30px', md: '60px' } }}
        >
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} style={{
                filter: 'drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 3px)',
                overflow: 'hidden',
                width: '100%',
                position: 'absolute'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height='180' preserveAspectRatio='none' viewBox="0 0 1440 205" fill={theme.palette[color].main}>
                    <path d="M0 99.1069H1440V129.05C1440 129.05 0 286.402 0 140.631C0 -5.1395 0 99.1069 0 99.1069Z" />
                    <path d="M0 0H1440V100H0V0Z" />
                </svg>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }} style={{
                filter: 'drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 3px)',
                overflow: 'hidden',
                width: '100%',
                position: 'absolute'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="90" preserveAspectRatio='none' viewBox="0 0 360 120" fill={theme.palette[color].main}>
                    <path d="M0 0H360V80C240 110 120 110 0 80V0Z" />
                </svg>
            </Box>

            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/** DESKTOP */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <NavLink to="/">
                            <img alt='Logo Tropical' src='/img/logo.png' className='logo' />
                        </NavLink>
                    </Box>

                    {/** MOBILE */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={toggleDrawer(true)}
                            color="warning"
                        >
                            <MenuIcon color='white' />
                        </IconButton>

                        <NavLink to="/">
                            <img alt='Logo Tropical' src='/img/logo.png' style={{
                                width: '66px',
                                position: 'absolute',
                                left: '50%',
                                marginLeft: '-33px', // Para centrar hay que quitar la mitad del ancho
                                top: '-6px'
                            }} className='logo' />
                        </NavLink>

                        <Drawer
                            anchor='left'
                            open={menuState}
                            onClose={toggleDrawer(false)}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            PaperProps={{
                                sx: {
                                    width: "80%",
                                    backgroundColor: theme.palette[color].main
                                },
                            }}
                        >
                            <Box
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                                marginTop={5}
                                textAlign='center'
                                height='100%'
                            >
                                <IconButton
                                    onClick={toggleDrawer(false)}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: 'white'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>

                                <Stack direction='column' justifyContent='space-between' height='100%'>
                                    <Box>
                                        <LinksMenu menu={menu} />
                                    </Box>
                                    {!user ? (
                                        <Profile user={user} showMenu={profileMenuClick} openLogin={setLoginOpened} />
                                    ) : (
                                        <ProfileMenu logout={logout} />
                                    )}
                                </Stack>
                            </Box>
                        </Drawer>
                    </Box>

                    {/** DESKTOP */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                        style={{
                            justifyContent: 'space-evenly',
                            marginLeft: '20px',
                            marginRight: '60px',
                            paddingTop: '30px'
                        }}>
                        <LinksMenu menu={menu} />
                    </Box>

                    {/** DESKTOP */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }} width={150}>
                        <Profile user={user} showMenu={profileMenuClick} openLogin={setLoginOpened}></Profile>
                    </Box>

                    {/** MENÚ USUARIO */}
                    <Menu
                        anchorEl={anchor}
                        open={showProfileMenu}
                        onClose={closeProfileMenu}
                        onClick={closeProfileMenu}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <ProfileMenu logout={logout} closeProfileMenu={closeProfileMenu} />
                    </Menu>
                </Toolbar>
            </Container>
            <LoginDialog open={loginOpened} handleClose={() => setLoginOpened(false)}></LoginDialog>
        </AppBar >
    )
}