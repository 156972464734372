import { Alert, AlertTitle, Button, CircularProgress, Container, Link } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link as RRLink, useSearchParams } from "react-router-dom";
import { FRONT_URLS } from "../config/url.config";
import { cancelReservation, getReservation } from "../service/store.service";

export default function CancelReservation() {
    const [params] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [reservation, setReservation] = useState();
    const [error, setError] = useState(false);
    const [cancelError, setCancelError] = useState(null);
    const [sending, setSending] = useState(false);

    const reservationId = params.get("id");
    useEffect(() => {
        loadReservation(reservationId);
    }, [reservationId]);

    const loadReservation = (id) => {
        setLoading(true);
        getReservation(id)
            .then((data) => setReservation(data))
            .catch(err => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }

    const cancel = () => {
        setSending(true);
        cancelReservation(reservationId)
            .then(() => {
                setCancelError("success");
            })
            .catch(err => {
                setCancelError("error");
            })
            .finally(() => setSending(false));
    }

    return (
        <Container>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {error && (
                        <Alert severity="error">Los datos de la reserva no son correctos</Alert>
                    )}
                    {reservation && (
                        cancelError !== 'success' ? (
                            <Alert
                                severity="warning"
                                variant="filled"
                                action={
                                    <Button
                                        variant="contained"
                                        disabled={sending}
                                        onClick={cancel}
                                        style={{
                                            margin: 20
                                        }}
                                    >
                                        {sending ? "Cancelando..." : "Cancelar reserva"}
                                        {sending && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Button>
                                }
                            >
                                <AlertTitle>¡Atención!</AlertTitle>
                                ¿Está seguro que quiere cancelar su reserva del {dayjs(reservation.date).format("DD/MM/YYYY [a las] HH:mm")} para {reservation.people} personas?
                                {cancelError && (
                                    <Alert severity="error" variant="filled" style={{ marginTop: 20 }}>
                                        Se ha producido un error al cancelar la reserva <br/>
                                        Inténtelo de nuevo y si no funciona, póngase en contacto con nosotros por teléfono al 971 66 11 17
                                    </Alert>
                                )}
                            </Alert>
                        ) : (
                            <Alert severity="success">
                                Se ha cancelado la reserva correctamente. <br/>
                                Si lo desea, puede volver a reservar a través del menú <Link component={RRLink} to={`/${FRONT_URLS.reservation}`}>Reservas</Link>.
                            </Alert>
                        )
                    )}
                </>
            )}
        </Container>
    );
}