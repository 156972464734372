import { createContext, useEffect, useState } from "react";
import { useEffectOnlyOnUpdate } from "../hooks/useEffectOnUpdate";
import { getSessionUser, signin, signout } from "../service/auth.service";
import { getTicket, saveTicket } from "../service/store.service";

export const TicketContext = createContext();

export const TicketProvider = ({ children }) => {
  const emptyTicket = { id: undefined, ticketLines: [], voucher: undefined };
  const [ticket, setTicket] = useState(emptyTicket);

  const addItem = (item, index) => {
    ticket.ticketLines.splice(index ?? ticket.ticketLines.length + 1, index !== undefined ? 1 : 0, item);
    setTicket({ ...ticket, ticketLines: [...ticket.ticketLines] });
  }

  const removeItem = (index) => {
    ticket.ticketLines.splice(index, 1);
    setTicket({ ...ticket, ticketLines: [...ticket.ticketLines] });
  }

  const resetTicket = async () => {
    //setTicket(emptyTicket);
    await saveTicket(emptyTicket);
    await refreshTicket();
  }

  const addVoucher = (voucher) => {
    setTicket({ ...ticket, voucher: voucher });
  }

  const removeVoucher = () => {
    setTicket({ ...ticket, voucher: undefined });
  }

  const getTotal = () => {
    return Math.max(getSubtotal() - (ticket.voucher?.quantity ?? 0), 0);
  }

  const getSubtotal = () => {
    const sumExtras = (extras) => {
      return extras.reduce((total, item) => total + item.price, 0);
    }
    return ticket.ticketLines.reduce((total, item) => total + (item.price * item.quantity) + (sumExtras(item.extras) * item.quantity), 0);
  };

  // Que haya artículos que permitan envío
  const validateDelivery = () => {
    return !!ticket.ticketLines.find(i => i.delivery);
  }

  const getTicketLines = () => {
    return ticket.ticketLines;
  }

  const refreshTicket = async () => {
    await getTicket()
      .then((ticket) => {
        setTicket(ticket || emptyTicket);
      });
  }

  /*useEffect(() => {
    // La primera carga, lo recoge de la session del back
    refreshTicket();
  }, []);*/

  useEffectOnlyOnUpdate(() => {
    // Envia al back para guardar ticket en session
    saveTicket(ticket);
  }, [ticket]);

  return (
    <TicketContext.Provider
      value={{
        ticket,
        addItem,
        removeItem,
        resetTicket,
        addVoucher,
        removeVoucher,
        getTotal,
        getSubtotal,
        validateDelivery,
        getTicketLines,
        refreshTicket
      }}
    >
      {children}
    </TicketContext.Provider>
  );
}

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  const login = async (user, pass) => {
    return (await signin(user, pass)
      .then((u) => {
        setUser(u);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      }));
  }

  const logout = async () => {
    await signout()
      .then(() => setUser(null))
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    updateSessionUser();
  }, []);

  const updateSessionUser = () => {
    getSessionUser()
      .then(u => setUser(u))
      .catch(e => console.log(e));
  }

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        updateSessionUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}