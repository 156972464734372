import { useEffect, useState } from "react";
import { getPage } from "../service/store.service";

export default function EditablePage({ page, lang }) {
    const [content, setContent] = useState();

    useEffect(() => {
        getPage(page, lang)
            .then((pContent) => setContent(pContent))
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, lang]);

    return (
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
    );
}