import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Link, Switch, TextField } from "@mui/material";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { Link as RRLink } from "react-router-dom";
import { TicketContext, UserContext } from '../context/Context';
import { doCheckout } from '../service/store.service';
import StreetInput from './StreetInput';
import { FRONT_URLS } from '../config/url.config';
import { TicketOrigin } from '../utils/TicketOrigin';

const Checkout = forwardRef((props, ref) => {
    const { schedule, deliveryType } = props;
    const { user } = useContext(UserContext);
    const ticketContext = useContext(TicketContext);

    const [open, setOpen] = useState(false);
    const [delivery, setDelivery] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const [street, setStreet] = useState(user?.street ?? null);
    const [streetDetail, setStreetDetail] = useState(user?.streetDetail ?? '');
    const [name, setName] = useState(user?.name ?? '');
    const [phone, setPhone] = useState(user?.phone ?? '');
    const [mail, setMail] = useState(user?.mail ?? '');
    const [policy, setPolicy] = useState(user ? true : false);

    const [error, setError] = useState(false);

    useImperativeHandle(ref, () => ({
        open(pDelivery) {
            setError(false);
            setDelivery(pDelivery);
            setOpen(true);
        }
    }));

    const close = () => {
        setOpen(false);
    }

    const mountTicket = () => {
        ticketContext.ticket = {
            ...ticketContext.ticket,
            user: user?.id,
            address: delivery ? `${street} ${streetDetail}` : '',
            name: name,
            phone: phone,
            mail: mail,
            scheduled: schedule,
            deliveryType: deliveryType,
            origin: TicketOrigin.WEB,
        }

        return ticketContext.ticket;
    }

    const createHidden = (name, value) => {
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", name);
        input.setAttribute("value", value);

        return input;
    }

    const validateMail = () => {
        const validMail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (!validMail.test(mail)) {
            return false;
        }
        return true;
    }

    const validate = () => {
        // Comprobar si estan informados todos los campos
        if (!name || !phone || !mail || (delivery && (!street || !streetDetail))) {
            setError("Tiene que rellenar todos los campos");
            return false;
        }
        // Validar correo
        if (!validateMail()) {
            setError("La dirección de correo no es válida");
            return false;
        }
        if (!policy) {
            setError("Tiene que aceptar la política de privacidad");
            return false;
        }
        return true;
    }

    const checkout = () => {
        if (!validate()) return;
        setWaiting(true);
        setError(false);
        // Comprobaciones en el back y redirección al pago o mostrar error
        doCheckout(mountTicket())
            .then((data) => {
                // Redirect a url que venga del back (con parametros merchantParameters, signature y signatureVersion para el pago)
                // TODO: Mirar si usar useSubmit para no tener que crear el form.
                const paymentForm = document.createElement("form");
                document.body.appendChild(paymentForm);
                paymentForm.action = data.url;
                paymentForm.method = data.method;

                if (data.merchantParameters) paymentForm.appendChild(createHidden('Ds_MerchantParameters', data.merchantParameters));
                if (data.signature) paymentForm.appendChild(createHidden('Ds_Signature', data.signature));
                if (data.signatureVersion) paymentForm.appendChild(createHidden('Ds_SignatureVersion', data.signatureVersion));

                paymentForm.submit();
            })
            .catch((e) => {
                setError(e.response.data);
                setWaiting(false);
                console.log("error", e);
            });
    }

    return (
        <Dialog
            onClose={close}
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                Finalizar pedido
                <IconButton
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} marginTop={0}>
                    {delivery && (
                        <>
                            <Grid item xs={12}>
                                Confirme los datos de envío.
                            </Grid>
                            <Grid item xs={12}>
                                <StreetInput value={street} setValue={setStreet} error={!!error && !streetDetail} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='streetDetail'
                                    label='Número, piso, puerta'
                                    type='text'
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={streetDetail}
                                    onChange={(e) => setStreetDetail(e.currentTarget.value)}
                                    error={!!error && !streetDetail}
                                />
                            </Grid>
                        </>
                    )}
                    {/** Cuando se pueda hacer pago en 1 click, podemos volver a poner la información de la tarjeta */}
                    {/*user && (
                            <Grid item xs={12}>
                                Info tarjetas
                            </Grid>
                            )*/}
                    {!user && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    name='name'
                                    label='Nombre'
                                    type='text'
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.currentTarget.value)}
                                    error={!!error && !name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='phone'
                                    label="Teléfono"
                                    type="number"
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={phone}
                                    onChange={(e) => setPhone(e.currentTarget.value)}
                                    error={!!error && !phone}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='mail'
                                    label='Correo electrónico'
                                    type='email'
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={mail}
                                    onChange={(e) => setMail(e.currentTarget.value)}
                                    error={!!error && (!mail || !validateMail())}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl error={!!error && !policy}>
                                    <FormControlLabel
                                        control={<Switch name="policy" color="secondary" checked={policy} onChange={() => setPolicy(!policy)} />}
                                        label={<div>He leído la <Link component={RRLink} target="_blank" to={`/${FRONT_URLS.privacy}`}>Política de privacidad</Link> y doy mi consentimiento para el tratamiento de datos</div>}
                                    />
                                    <FormHelperText>{!!error && !policy && "Tiene que aceptar la política de privacidad"}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                {error && (
                    <Alert severity='error' style={{ width: '100%' }}>{error}</Alert>
                )}
                <Button
                    variant="contained"
                    //sx={{ width: { xs: '100%', md: '40%' } }}
                    disabled={waiting}
                    onClick={checkout}
                >
                    {waiting ? "Comprobando..." : "Pagar"}
                    {waiting && (
                        <CircularProgress
                            size={24}
                            sx={{
                                //color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default Checkout;