import { FRONT_URLS } from "../config/url.config";

const menu = [
    {
        title: 'Aviso legal',
        path: FRONT_URLS.legal
    },
    {
        title: 'Política de privacidad',
        path: FRONT_URLS.privacy
    },
    {
        title: 'Política de cookies',
        path: FRONT_URLS.cookies
    },
    {
        title: 'Condiciones de venta',
        path: FRONT_URLS.sale
    },
];

export default menu;