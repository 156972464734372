import { useContext } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Activation from '../components/Activation';
import ChangePass from '../components/ChangePass';
import Confirmation from '../components/Confirmation';
import EditablePage from '../components/EditablePage';
import ErrorPage from '../components/ErrorPage';
import Login from '../components/Login';
import MyOrders from '../components/MyOrders';
import Orders from '../components/Orders';
import Profile from '../components/Profile';
import Reservation from '../components/Reservation';
import ReservationCanceled from '../components/ReservationCanceled';
import SignUp from '../components/SignUp';
import SignedUp from '../components/SignedUp';
import Vouchers from '../components/Vouchers';
import { FRONT_URLS } from '../config/url.config';
import { UserContext } from '../context/Context';
import Tpv from '../components/Tpv';
import CancelReservation from '../components/CancelReservation';

// Rutas que no se deben mostrar a usuarios no autenticados
const ProtectedRoutes = () => {
    const { user } = useContext(UserContext);

    return user ? <Outlet /> : <Navigate to="/" replace />;
};

// Rutas que no se deben mostrar a usuarios registrados
const NoUserRoutes = () => {
    const { user } = useContext(UserContext);

    return !user ? <Outlet /> : <Navigate to="/" replace />;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "*",
                element: <ErrorPage />,
            },
            { index: true, element: <EditablePage page="home" lang="es" /> },
            {
                path: FRONT_URLS.restaurant,
                element: <EditablePage page="restaurant" lang="es" />,
            },
            {
                path: FRONT_URLS.menu,
                element: <EditablePage page="menu" lang="es" />,
            },
            {
                path: FRONT_URLS.privacy,
                element: <EditablePage page="privacy" lang="es" />
            },
            {
                path: FRONT_URLS.legal,
                element: <EditablePage page="legal" lang="es" />
            },
            {
                path: FRONT_URLS.sale,
                element: <EditablePage page="sale" lang="es" />
            },
            {
                path: FRONT_URLS.cookies,
                element: <EditablePage page="cookies" lang="es" />
            },
            {
                path: FRONT_URLS.reservation,
                element: <Reservation />,
            },
            {
                path: FRONT_URLS.orders,
                element: <Orders />,
            },
            {
                path: FRONT_URLS.confirmed,
                element: <Confirmation confirmed />,
            },
            {
                path: FRONT_URLS.denied,
                element: <Confirmation />,
            },
            {
                path: FRONT_URLS.login,
                element: <Login />,
            },
            {
                path: FRONT_URLS.activation,
                element: <Activation />,
            },
            {
                path: FRONT_URLS.reservationCanceled,
                element: <ReservationCanceled />,
            },
            {
                path: FRONT_URLS.cancelReservation,
                element: <CancelReservation />,
            },
            {
                path: FRONT_URLS.changePass + "/:token",
                element: <ChangePass />,
            },
            {
                element: <NoUserRoutes />,
                children: [
                    {
                        path: FRONT_URLS.signup,
                        element: <SignUp />,
                    },
                    {
                        path: FRONT_URLS.signedup,
                        element: <SignedUp />,
                    },
                ]
            },
            {
                element: <ProtectedRoutes />,
                children: [
                    {
                        path: FRONT_URLS.myorders,
                        element: <MyOrders />,
                    },
                    {
                        path: FRONT_URLS.vouchers,
                        element: <Vouchers />,
                    },
                    {
                        path: FRONT_URLS.profile,
                        element: <Profile />,
                    },
                ]
            },
        ]
    }, {
        path: FRONT_URLS.tpv,
        element: <Tpv />,
        errorElement: <ErrorPage />,
    },
]);

export default router;