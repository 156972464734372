import ReplayIcon from '@mui/icons-material/Replay';
import { CircularProgress, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getVouchers } from "../service/store.service";
import { euro } from "../utils/Currency";

const ThCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function Vouchers() {

    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);

    const rowsPerPage = 6;
    const emptyRows = (loading || vouchers.length === 0) ? 1 : (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vouchers.length) : 0);

    useEffect(() => {
        loadVouchers();
    }, []);

    const loadVouchers = () => {
        setLoading(true);
        getVouchers()
            .then(setVouchers)
            .catch(e => console.log(e))
            .finally(() => setLoading(false));
    }

    return (
        <Container>
            <Typography variant="h5">
                Mis bonos
            </Typography>
            <Typography variant="subtitle1">
                Aquí puede ver los códigos de descuento que ha ido generando con sus pedidos realizados y el estado en que se encuentran
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <ThCell align="center">Código</ThCell>
                            <ThCell align="center">Generado</ThCell>
                            <ThCell align="center">Usado</ThCell>
                            <ThCell align="center">Caducidad</ThCell>
                            <ThCell align="center">Importe</ThCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? vouchers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : vouchers
                        ).map(row => (
                            <StyledTableRow key={row.id}>
                                <TableCell align="center">{row.id}</TableCell>
                                <TableCell align="center">{dayjs(row.created).format("DD/MM/YYYY")}</TableCell>
                                <TableCell align="center">{row.used ? dayjs(row.used).format("DD/MM/YYYY") : ''}</TableCell>
                                <TableCell align="center">{dayjs(row.expire).format("DD/MM/YYYY")}</TableCell>
                                <TableCell align="center">{euro.format(row.quantity)}</TableCell>
                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    {loading ? (
                                        <CircularProgress />
                                    ) : (vouchers.length === 0 ? (
                                        <Typography variant="body1">
                                            No se han encontrado bonos
                                            <IconButton onClick={() => loadVouchers()} style={{ marginInline: 20 }}>
                                                <ReplayIcon />
                                            </IconButton>
                                        </Typography>
                                    ) : "")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                showFirstButton
                                showLastButton
                                colSpan={5}
                                count={vouchers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, p) => setPage(p)}
                                labelDisplayedRows={({ from, to, count }) => `${from} – ${to} de ${count !== -1 ? count : `más de ${to}`}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Container>
    );
}