import { Link, useTheme } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import { Link as RRLink } from 'react-router-dom';
import { FRONT_URLS } from "../config/url.config";

export default function Cookie() {
    const theme = useTheme();

    return (
        <CookieConsent
            buttonText="Entendido"
            declineButtonText="Rechazar"
            acceptOnScroll
            enableDeclineButton
            flipButtons
            style={{ zIndex: 9999, fontSize: '14px', padding: 0 }}
            contentStyle={{ margin: '0 15px' }}
            buttonStyle={{
                background: theme.palette.success.main,
                color: theme.palette.success.contrastText,
                borderRadius: '4px',
                margin: '8px 15px'
            }}
            declineButtonStyle={{
                background: theme.palette.error.main,
                color: theme.palette.error.contrastText,
                borderRadius: '4px',
                margin: '8px 15px'
            }}
        >
            Esta web utiliza cookies propias para el uso del servicio ofrecido a nuestros visitantes. <Link underline="none" color='white' rel="noopener" variant='body2' component={RRLink} target="_blank" to={`/${FRONT_URLS.cookies}`}>Detalles</Link>
        </CookieConsent>
    );
}