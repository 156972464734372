import { Badge, Card, CardActionArea, CardContent, Typography, styled } from "@mui/material";
import { useRef } from "react";
import { euro } from '../utils/Currency';
import ItemDetail from './ItemDetail';
import FreeItemDetail from "./FreeItemDetail";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 20,
        top: 5,
        padding: '10px 10px',
    },
}));

export default function Item({ item, style }) {

    const details = useRef();

    const openDetails = () => {
        details.current.openDetails({
            quantity: 1,
            comment: '',
            extras: []
        });
    }

    return (
        <>
            <StyledBadge badgeContent={item.brandNew ? 'Nuevo' : null} color="warning" overlap="circular" sx={{ width: 1, height: 1 }}>
                <Card style={style} onClick={() => openDetails()} sx={{ width: 1, height: 1 }}>
                    <CardActionArea sx={{ height: 1 }}>
                        {/**
            <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                //image="/static/images/cards/contemplative-reptile.jpg"
            />
             */}
                        <CardContent sx={{ height: 1 }}>
                            <Typography variant="body2" sx={{ height: '50%' }}>
                                {item.name}
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'right', height: '50%' }}>
                                {euro.format(item.price)}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </StyledBadge>

            {item.id ? (
                <ItemDetail ref={details} itemId={item.id} />
            ) : (
                <FreeItemDetail ref={details} />
            )}
        </>
    );
}