import axios from "axios"
import { ADMIN_URLS, API_URLS } from "../config/url.config";

axios.defaults.withCredentials = true;

export const getPage = async (page, lang) => {
  return (await axios.get(`${API_URLS.pages}`, {
    params: {
      page: page,
      lang: lang
    }
  })).data;
}

export const updateUser = async (user) => {
  if (user.id) {
    return (await axios.put(`${API_URLS.users}`, user)).data;
  } else {
    return (await axios.post(`${API_URLS.users}`, user)).data;
  }
}

export const updatePass = async (user) => {
  return (await axios.put(`${API_URLS.users}/${API_URLS.pass}`, user)).data;
}

export const getFamilies = async () => {
  return (await axios.get(`${API_URLS.families}`)).data;
}

export const getItemDetails = async (id) => {
  return (await axios.get(`${API_URLS.items}/${id}`)).data;
}

export const getScheduleList = async (getAll = false) => {
  return (await axios.get(`${API_URLS.scheduler}`, {
    params: {
      getAll: getAll
    }
  })).data;
}

export const getTypeOpened = async (orderType) => {
  return (await axios.get(`${API_URLS.scheduler}/${orderType}`)).data;
}

export const getOpened = async () => {
  return (await axios.get(`${API_URLS.scheduler}/${API_URLS.opened}`)).data;
}

export const doCheckout = async (ticket) => {
  return (await axios.post(`${API_URLS.checkout}`, ticket)).data;
}

export const saveTicket = async (ticket) => {
  try {
    return (await axios.post(`${API_URLS.ticket}`, ticket)).data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export const getTicket = async () => {
  try {
    return (await axios.get(`${API_URLS.ticket}`)).data;
  } catch (e) {
    console.log("[store service] getTicket", e);
    return null;
  }
}

export const getStreets = async () => {
  return (await axios.get(`${API_URLS.streets}`)).data;
}

export const getReservationTimes = async (date) => {
  return (await axios.get(`${API_URLS.reservation}/${API_URLS.time}`, {
    params: { date: date }
  })).data;
}

export const createReservation = async (reservation) => {
  return (await axios.post(`${API_URLS.reservation}`, reservation)).data;
}

export const getOrders = async () => {
  return (await axios.get(`${API_URLS.orders}`)).data;
}

export const getOrdersDetails = async (ticketId) => {
  return (await axios.get(`${API_URLS.orders}/${ticketId}`)).data;
}

export const checkVoucher = async (code) => {
  return (await axios.get(`${API_URLS.voucher}/${code}`)).data;
}

export const getVouchers = async () => {
  return (await axios.get(`${API_URLS.vouchers}`)).data;
}

export const getPromos = async () => {
  return (await axios.get(`${API_URLS.promos}`)).data;
}

export const findUserData = async (phone) => {
  return (await axios.get(`${ADMIN_URLS.findUserByPhone}`, {
    params: {
      phone
    }
  })).data;
}

export const findTickets = async (id, startDate, endDate, page) => {
  return (await axios.get(`${ADMIN_URLS.tickets}`, {
    params: {
      id: id,
      startDate: startDate,
      endDate: endDate,
      page: page
    }
  })).data;
}

export const changeTicketStatus = async (id, status) => {
  return (await axios.put(`${ADMIN_URLS.tickets}`, {
    id: id,
    status: status
  })).data;
}

export const recoverTicket = async (id) => {
  try {
    return (await axios.post(`${ADMIN_URLS.tickets}`, { id })).data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export const getReservation = async (id) => {
  try {
    return (await axios.get(`${API_URLS.reservation}`, {
      params: {
        id: id
      }
    })).data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export const cancelReservation = async (id) => {
  try {
    return (await axios.delete(`${API_URLS.reservation}`, {
      params: {
        id: id
      }
    })).data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}