import { Container, ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { Outlet } from 'react-router-dom';
import './App.css';
import Cookie from './components/Cookie';
import Header from './components/Header';
import { TicketProvider, UserProvider } from './context/Context';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#455a64',
    },
    secondary: {
      main: '#0277bd',
      light: '#0685d0',
      dark: '#0e547c'
    },
    warning: {
      main: '#ffc400',
    },
    info: {
      main: '#81d4fa',
    },
    success: {
      main: '#059412',
    },
  },
});

function App() {
  return (
    <UserProvider>
      <TicketProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <ThemeProvider theme={theme}>
            <Header />
            <Container sx={{paddingBottom: '100px', flex: 1}}>
              <Outlet />
              <Cookie />
            </Container>
            <Footer />
          </ThemeProvider>
        </LocalizationProvider>
      </TicketProvider>
    </UserProvider>
  );
}

export default App;
