import { AppBar, Box, Container, Link, Toolbar } from '@mui/material';
import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import menu from '../routes/footerMenu';

export default function Footer() {
    return (
        <AppBar
            sx={{ top: 'auto', bottom: 0 }}
            elevation={0}
            className='footer'
            style={{
                position: 'absolute',
                bottom: 0
            }}
        >
            <Container>
                <Toolbar disableGutters style={{ minHeight: '40px' }}>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}
                        style={{
                            justifyContent: 'space-evenly',
                        }}
                    >
                        {menu.map((pMenu) => (
                            <Link
                                component={RRLink}
                                to={pMenu.path}
                                key={pMenu.path}
                                color='white'
                                underline='none'
                                variant='caption'
                            >
                                {pMenu.title}
                            </Link>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}