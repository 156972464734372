import ShoppingCart from '@mui/icons-material/ShoppingCartRounded';
import { Alert, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, Fab, Grid, Stack, Tab, Tabs, Typography, styled } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { TicketContext, UserContext } from '../context/Context';
import { getFamilies, getPromos } from "../service/store.service";
import FoodLoader from './FoodLoader';
import Item from "./Item";
import Login from "./Login";
import Ticket from './Ticket';
import FreeItemIcon from '@mui/icons-material/Add';
import FreeItemDetail from './FreeItemDetail';

const LoginDialog = ({ open, handleClose }) => {
    return (
        <Dialog open={open} maxWidth="md">
            <DialogTitle>Acceso usuarios</DialogTitle>
            <DialogContent dividers style={{ padding: 0 }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    divider={
                        <>
                            <Divider orientation='horizontal' sx={{ display: { xs: 'flex', sm: 'none' }, marginY: '20px' }} />
                            <Divider orientation='vertical' sx={{ display: { xs: 'none', sm: 'flex' } }} flexItem />
                        </>
                    }
                    spacing={0}
                    paddingY='20px'
                >
                    <Box sx={{ width: { xs: 'auto', sm: '50%' }, paddingX: '20px' }}>
                        <Login handleClose={handleClose}></Login>
                    </Box>
                    <Box sx={{ textAlign: 'center', width: { xs: 'auto', sm: '50%' }, paddingX: '20px', backgroundColor: 'white' }} zIndex={2}>
                        <Typography variant="body1" marginBottom="30px" textAlign='center'>
                            También puedes hacer el pedido sin registrarte
                        </Typography>
                        <Button style={{ textTransform: 'none' }} onClick={handleClose} sx={{ width: { xs: '100%', sm: '200px' } }} color="secondary" variant="contained">Continuar sin registro</Button>
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const FamilyTabs = styled(Tabs)({
    backgroundColor: '#eaeaea',
    borderRadius: '50px',
    boxShadow: '0px 2px 6px 0px #aaaaaa',
    '& .MuiTabs-indicator': {
        backgroundColor: 'red'
    },
});

const FreeItemButton = () => {
    const freeItem = useRef();
    const newFreeItem = () => {
        freeItem.current.openDetails({
            quantity: 1,
            comment: '',
            extras: []
        });
    }
    
    return (
        <>
            <Fab
                variant="extended"
                size="medium"
                color="info"
                style={{
                    position: 'fixed',
                    bottom: 40,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textTransform: 'none'
                }}
                onClick={() => newFreeItem()}
            >
                <FreeItemIcon sx={{ mr: 1 }} />
                Texto libre
            </Fab>
            <FreeItemDetail ref={freeItem} />
        </>
    );
}

export default function Orders({ tpv }) {
    // Si ha ocurrido un fallo, se hace un redirect a esta pantalla con el error state a true para mostrar el mensaje
    const { state } = useLocation();
    const { error } = state || {};
    const [showError, setShowError] = useState(error);

    const ticketContext = useContext(TicketContext);
    const { user } = useContext(UserContext);
    const [showDialog, setShowDialog] = useState(false);
    const [showCart, setShowCart] = useState(false);

    const [loading, setLoading] = useState(false);
    const [families, setFamilies] = useState();
    const [promoList, setPromoList] = useState([]);
    const [isMenuFixed, setIsMenuFixed] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [initialWidth, setInitialWidth] = useState(0);
    const topMargin = 10;

    useEffect(() => {
        if (user !== undefined) setShowDialog(!user);
    }, [user]);

    useEffect(() => {
        loadFamilies();
        getPromoList();
        ticketContext.refreshTicket();

        const handleScroll = () => {
            const headerElement = document.querySelector("header");
            const headerStyles = window.getComputedStyle(headerElement);
            const marginTop = parseFloat(headerStyles.marginTop) || 0;
            const marginBottom = parseFloat(headerStyles.marginBottom) || 0;
            const headerHeight = headerElement.offsetHeight + marginTop + marginBottom;
            handleWidth();

            if (window.scrollY > headerHeight - topMargin) {
                setIsMenuFixed(true);
            } else {
                setIsMenuFixed(false);
            }
        };

        const handleWidth = () => {
            const pageContainer = document.querySelector("[name=pageContainer]");
            setInitialWidth(pageContainer.offsetWidth);
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleWidth);

        // Limpieza del evento al desmontar el componente
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleWidth);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPromoList = () => {
        getPromos()
            .then((list) => {
                setPromoList(list);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const loadFamilies = () => {
        setLoading(true);
        getFamilies()
            .then((pFamilies) => {
                setFamilies(pFamilies);
            })
            .catch((err) => {
                console.log("error", err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Box name="pageContainer" style={{ marginBottom: 20 }}>
            {promoList.map((promo, index) => (
                <Alert key={index} severity='info' style={{ marginBottom: 10 }}>{promo.description}</Alert>
            ))}
            {families && (
                <FamilyTabs
                    value={selectedTab}
                    onChange={(event, newValue) => setSelectedTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    style={isMenuFixed ? { position: 'fixed', top: topMargin, width: initialWidth, zIndex: 9 } : {}}
                >
                    {
                        families.map((family) => (
                            <Tab key={family.id} label={family.name.toUpperCase()} href={`#${family.id}`} />
                        ))
                    }
                </FamilyTabs>
            )}
            {loading && (
                <FoodLoader />
            )}
            <Box style={isMenuFixed ? { marginTop: '130px' } : {}}>
                {!loading && !families && (
                    <Alert
                        severity='error'
                        style={{ marginTop: 10 }}
                        action={
                            <Button color="inherit" size="small" onClick={() => loadFamilies()}>
                                Reintentar
                            </Button>
                        }
                    >No se han encontrado resultados</Alert>
                )}
                {tpv && (
                    <FreeItemButton />
                )}
                {families &&
                    families.map((family) => (
                        <Box id={family.id} key={family.id}>
                            <h4>{family.name.toUpperCase()}</h4>
                            <Grid container spacing={2}>
                                {
                                    family.items.map((item) => {
                                        return (<Grid item xs={6} md={2.4} key={item.id}><Item item={item} /></Grid>)
                                    })
                                }
                            </Grid>
                        </Box>
                    ))
                }
                <Drawer
                    anchor='right'
                    open={showCart}
                    onClose={() => setShowCart(false)}
                >
                    <Ticket tpv={tpv} close={() => setShowCart(false)}></Ticket>
                </Drawer>
            </Box>
            {!tpv && (<LoginDialog open={showDialog} handleClose={() => setShowDialog(false)}></LoginDialog>)}
            <Fab
                color="primary"
                sx={{ position: 'fixed', bottom: 50, right: 20 }}
                onClick={() => setShowCart(true)}
            >
                <Badge badgeContent={ticketContext.ticket?.ticketLines?.length} color="secondary">
                    <ShoppingCart />
                </Badge>
            </Fab>
            <Dialog open={!!showError}>
                <DialogTitle>¡Atención!</DialogTitle>
                <DialogContent>Ha ocurrido un error procesando el pago de su pedido. Inténtelo otra vez.</DialogContent>
                <DialogActions><Button onClick={() => setShowError(null)}>Ok</Button></DialogActions>
            </Dialog>
        </Box>
    )
}