import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { Avatar, AvatarGroup, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Tooltip, Typography, Zoom, styled } from "@mui/material";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { TicketContext } from '../context/Context';
import { getItemDetails } from '../service/store.service';
import NumberInput from "./NumberInput";

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});

const CommentField = styled(TextField)({
    '& .MuiInputBase-input': {
        backgroundColor: 'white',
    }
});

const SuggestionChip = ({ item, onClick, checked }) => {
    const [selected, setSelected] = useState(checked);
    return (
        <Chip
            icon={selected ? <RemoveIcon /> : <AddIcon />}
            label={item.name}
            variant={selected ? 'filled' : 'outlined'}
            color='secondary'
            style={{
                width: '100%',
                justifyContent: 'start'
            }}
            onClick={() => {
                setSelected(!selected);
                onClick(!selected, item);
            }}
        />
    );
}

const ItemDetail = forwardRef((props, ref) => {
    const { itemId, edit } = props;
    const ticketContext = useContext(TicketContext);
    const [item, setItem] = useState();
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [comment, setComment] = useState('');
    const [extras, setExtras] = useState([]);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        openDetails(i) {
            setQuantity(i.quantity);
            setComment(i.comment);
            setExtras(i.extras);

            setOpen(true);
            setLoading(true);
            getItemDetails(itemId)
                .then((pItem) => {
                    setItem(pItem);
                })
                .catch((e) => {
                    console.log("[ItemDetail]", e);
                })
                .finally(() => setLoading(false));
        }
    }));

    const closeDetails = () => {
        setOpen(false);
    }

    const addItem = () => {
        item.comment = comment;
        item.quantity = quantity ?? 1;
        item.extras = extras;

        ticketContext.addItem(item, edit);
        closeDetails();
    }

    const addExtra = (selected, item) => {
        if (selected) { // Añade
            setExtras([...extras, item])
        } else { // Elimina
            setExtras(extras.filter((e) => e.id !== item.id));
        }
    }

    const selectedExtra = (sExtra) => {
        return extras.find((e) => e.id === sExtra.id) !== undefined;
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            onClose={closeDetails}
            open={open}
            fullWidth
            maxWidth="sm"
        >
            {item ? (
                <>
                    <DialogTitle>
                        {item?.name}
                        <IconButton
                            onClick={closeDetails}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box paddingBottom={2}>
                            {item.description}
                        </Box>
                        {item.famSuggestion && item.famSuggestion.items?.length > 0 && (
                            <>
                                <Typography>¿Quieres añadir {item.famSuggestion.name.toLowerCase()}?</Typography>
                                <Grid container spacing={1}>
                                    {
                                        item.famSuggestion.items.map((sItem, index) => (
                                            sItem?.online &&
                                            <Grid key={index} item xs={6} md={4}>
                                                <SuggestionChip onClick={addExtra} item={sItem} checked={() => selectedExtra(sItem)} />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </>
                        )}
                        {item.suggestion && item.suggestion.items?.length > 0 && (
                            <>
                                <Typography>¿Quieres añadir {item.suggestion.name.toLowerCase()}?</Typography>
                                <Grid container spacing={1}>
                                    {
                                        item.suggestion.items.map((sItem, index) => (
                                            sItem?.online &&
                                            <Grid key={index} item xs={6} md={4}>
                                                <SuggestionChip onClick={addExtra} item={sItem} checked={() => selectedExtra(sItem)} />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Stack width="100%" spacing={{ xs: 1 }} direction="row" useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                            <CommentField
                                label="¿Quieres añadir un comentario?"
                                placeholder="Por ejemplo, sin queso o sin orégano"
                                fullWidth
                                variant='filled'
                                size='small'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <AvatarGroup style={{
                                flex: 1,
                                justifyContent: 'flex-end'
                            }}>
                                {
                                    item?.allergens?.map((allergen) => (
                                        <Tooltip key={allergen.id} title={allergen.name} arrow placement="top-start">
                                            <Avatar alt={allergen.name} src={`data:image/png;base64,${allergen.image}`} sx={{ width: 28, height: 28 }} />
                                        </Tooltip>
                                    ))
                                }
                            </AvatarGroup>
                            <NumberInput value={quantity} onChange={(event, val) => setQuantity(val)} min={1} max={99} readOnly />
                            <Button
                                variant='contained'
                                color='primary'
                                style={{

                                }}
                                onClick={addItem}
                            >
                                {edit !== undefined ? 'Guardar' : 'Añadir'}
                            </Button>
                        </Stack>
                    </DialogActions>
                </>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
                    {loading ? (<CircularProgress />) : ("No se han encontrado los detalles del artículo")}
                </Box>
            )}
        </Dialog>
    );
});

export default ItemDetail;