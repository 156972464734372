import { useEffect, useState } from 'react';
import './Animations.css';

import { Box, Container, Typography } from "@mui/material";

export default function SignedUp() {
    
    const [openMail, setOpenMail] = useState(false);
    
    useEffect(() => {
        setTimeout(() => {
            setOpenMail(true);
        }, 2000);
        const interval = setInterval(() => {
            setOpenMail(!openMail);
        }, 3000);
        return () => {
            clearInterval(interval);
        }
    }, [openMail]);


    return (
        <Container>
            <Typography variant='h4' textAlign='center'>¡Ya casi está!</Typography>
            <Box position="relative">
                <div className={`letter-image ${openMail ? "letter-image-hover" : ""}`}>
                    <div class="animated-mail">
                        <div class="back-fold"></div>
                        <div class="letter">
                            <div class="letter-border"></div>
                            <div class="letter-title"></div>
                            <div class="letter-context"></div>
                            <div class="letter-stamp">
                                <div class="letter-stamp-inner"></div>
                            </div>
                        </div>
                        <div class="top-fold"></div>
                        <div class="body"></div>
                        <div class="left-fold"></div>
                    </div>
                    <div class="shadow"></div>
                </div>
            </Box>
            <Typography variant='body1' textAlign='center'>Revisa tu correo y sigue las instrucciones para activar la cuenta.</Typography>
        </Container>
    );
}