import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Container, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updatePass } from "../service/store.service";
import { FRONT_URLS } from "../config/url.config";
import { UserContext } from "../context/Context";
import { jwtDecode } from "jwt-decode";

export default function ChangePass() {
    const { token } = useParams();
    const decryptedToken = jwtDecode(atob(token));
    const { id, mail, expireTime } = decryptedToken;

    const [showPassword, setShowPassword] = useState(false);
    const [sendingPass, setSendingPass] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [snackType, setSnackType] = useState('error');

    const [pass, setPass] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    const [error, setError] = useState();

    const { updateSessionUser } = useContext(UserContext);
    const navigate = useNavigate();

    const validatePassChange = () => {
        // Valida que esten informados
        if (pass === '' || repeatPass === '') {
            setError('No puede dejar los campos en blanco');
            return;
        }
        // Valida que sean iguales
        if (pass !== repeatPass) {
            setError('Las contraseñas no coinciden');
            return;
        }

        setSendingPass(true);
        updatePass({
            id,
            mail,
            pass: pass,
            repeatedPass: repeatPass,
            token
        })
            .then(() => {
                updateSessionUser();
                showSnack('Se ha actualizado la contraseña correctamente', 'success');
                setTimeout(() => {
                    navigate(`/${FRONT_URLS.profile}`);
                }, 2000);
            })
            .catch((e) => {
                console.log(e);
                showSnack(e.response?.data ?? e.message, 'error');
            })
            .finally(() => {
                setSendingPass(false);
            });
    }

    const showSnack = (msg, type) => {
        setSnackMsg(msg);
        setSnackType(type);
    }

    return (
        <Container>
            {!token ? (
                <Alert severity="error">
                    La ruta es incorrecta
                </Alert>
            ) : (
                dayjs(expireTime).isBefore(dayjs()) ? (
                    <Alert severity="error">
                        El tiempo para cambiar la contraseña ha expirado. Solicite un nuevo enlace.
                    </Alert>
                ) : (
                    <>
                        <Typography variant="subtitle1" marginBottom={3}>
                            Puede cambiar su contraseña
                        </Typography>
                        <Grid container spacing={2} marginBottom={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Correo electrónico"
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    value={mail}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    name="password"
                                    label="Contraseña"
                                    type={showPassword ? 'text' : 'password'}
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={pass}
                                    onChange={(e) => setPass(e.target.value)}
                                    error={!!error}
                                    helperText={error}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onMouseDown={() => setShowPassword(true)}
                                                onTouchStart={() => setShowPassword(true)}
                                                onMouseUp={() => setShowPassword(false)}
                                                onTouchEnd={() => setShowPassword(false)}
                                                tabIndex={-1}
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    name="repeatPassword"
                                    label="Repetir contraseña"
                                    type={showPassword ? 'text' : 'password'}
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={repeatPass}
                                    onChange={(e) => setRepeatPass(e.target.value)}
                                    error={!!error}
                                    helperText={error}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onMouseDown={() => setShowPassword(true)}
                                                onTouchStart={() => setShowPassword(true)}
                                                onMouseUp={() => setShowPassword(false)}
                                                onTouchEnd={() => setShowPassword(false)}
                                                tabIndex={-1}
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ width: { xs: '100%', md: '40%' } }}
                                        disabled={sendingPass}
                                        onClick={validatePassChange}
                                    >
                                        {sendingPass ? "Enviando..." : "Cambiar contraseña"}
                                    </Button>
                                    {sendingPass && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )
            )}

            <Snackbar
                open={!!snackMsg}
                autoHideDuration={4000}
                onClose={() => setSnackMsg(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert variant="filled" elevation={6} onClose={() => setSnackMsg(null)} severity={snackType} sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </Container>
    )
}