import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, Zoom, styled } from "@mui/material";
import dayjs from 'dayjs';
import { forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FRONT_URLS } from '../config/url.config';
import { getOrdersDetails, saveTicket } from '../service/store.service';
import { euro } from '../utils/Currency';
import { TicketStatus } from '../utils/TicketStatus';

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const TicketDetails = forwardRef((props, ref) => {
    const [ticket, setTicket] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        openDetails(id) {
            setOpen(true);
            getOrdersDetails(id)
                .then((pTicket) => {
                    setTicket(pTicket);
                })
                .catch((e) => {
                    console.log("[TicketDetails]", e);
                });
        }
    }));

    const closeDetails = () => {
        setOpen(false);
        setTicket(null);
    }

    /**
     * Si keepId == true, significa reintento de pago, si no, copia de ticket y no conservamos id
     */ 
    const copyTicket = async (ticket, keepId) => {
        if (!keepId) {
            ticket.id = null;
        }
        await saveTicket(ticket);
        navigate(`/${FRONT_URLS.orders}`);
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            onClose={closeDetails}
            open={open}
            fullWidth
            maxWidth="sm"
        >
            {ticket ? (
                <>
                    <DialogTitle>
                        Detalles del ticket #{ticket.id}
                        <IconButton
                            onClick={closeDetails}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>Dirección: {ticket.address}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ticket.ticketLines.map((ticketLine, index) => (
                                        <StyledTableRow key={index}>
                                            <TableCell align="center" style={{ verticalAlign: 'top' }} width={20}>{ticketLine.quantity}</TableCell>
                                            <TableCell>
                                                {ticketLine.name}
                                                {ticketLine.extras.map((extra, index) => (
                                                    <Typography key={`extra_${index}`} style={{
                                                        fontSize: 11,
                                                        paddingLeft: 25,
                                                        paddingTop: 2,
                                                        paddingBottom: 2,
                                                    }}>
                                                        {extra.name}
                                                    </Typography>
                                                ))}
                                                <Typography style={{
                                                    color: '#a0a0a0', //theme.palette.info.contrastText,
                                                    fontSize: 12,
                                                    paddingBottom: 1,
                                                    paddingTop: 1
                                                }}>
                                                    {ticketLine.comment}
                                                </Typography>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'right' }} variant='head'>
                                            Total {euro.format(ticket.total)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'center', border: 0, paddingTop: 15 }}>
                                            {ticket.status === TicketStatus.PAYING.id ? (
                                                dayjs().isSame(ticket.date, 'date') && (
                                                    <Button onClick={() => copyTicket(ticket, true)} variant='contained' color='warning'>Reintentar pago</Button>
                                                )
                                            ) : (
                                                <Button onClick={() => copyTicket(ticket, false)} variant='contained'>Copiar mismo pedido</Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
                    <CircularProgress />
                </Box>
            )}
        </Dialog>
    );
});

export default TicketDetails;