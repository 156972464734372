import axios from "axios";
import { API_URLS } from "../config/url.config";

axios.defaults.withCredentials = true;

export const register = (username, password) => {
    /*return axios.post(`${API_URLS.users}/${API_URLS.signup}`, {
      username,
      password,
    });*/
};

export const signin = async (username, password) => {
    return (await axios.post(`${API_URLS.users}/${API_URLS.signin}`, {
        username,
        password,
    })).data;
};

export const signout = async () => {
    return await axios.delete(`${API_URLS.users}`);
};

export const getSessionUser = async () => {
    return (await axios.get(`${API_URLS.users}`)).data;
};

export const recoveryPassword = async (username) => {
    return (await axios.put(`${API_URLS.users}/${API_URLS.recovery}`, {
        username
    })).data;
}