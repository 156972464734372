import { Container, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { TicketProvider, UserProvider } from "../context/Context";
import Orders from "./Orders";
import TpvHeader from "./TpvHeader";

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#455a64',
        },
        secondary: {
            main: '#0277bd',
            light: '#0685d0',
            dark: '#0e547c'
        },
        warning: {
            main: '#ffc400',
        },
        info: {
            main: '#81d4fa',
        },
        success: {
            main: '#059412',
        },
    },
});

export default function Tpv() {
    return (
        <UserProvider>
            <TicketProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <ThemeProvider theme={theme}>
                        <TpvHeader />
                        <Container sx={{ paddingBottom: '100px', flex: 1 }}>
                            <Orders tpv />
                        </Container>
                    </ThemeProvider>
                </LocalizationProvider>
            </TicketProvider>
        </UserProvider>
    )
}