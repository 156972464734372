import { FRONT_URLS } from "../config/url.config";

const menu = [
    {
        title: 'El restaurante',
        path: FRONT_URLS.restaurant
    },
    {
        title: 'Carta',
        path: FRONT_URLS.menu
    },
    {
        title: 'Reservas',
        path: FRONT_URLS.reservation
    },
    {
        title: 'Pedidos',
        path: FRONT_URLS.orders
    },
];

export default menu;