import ReplayIcon from '@mui/icons-material/Replay';
import ViewDetailsIcon from '@mui/icons-material/ZoomInRounded';
import { CircularProgress, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography, styled, tableCellClasses } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { getOrders } from "../service/store.service";
import { euro } from "../utils/Currency";
import TicketDetails from './TicketDetails';
import { TicketStatus } from '../utils/TicketStatus';

const ThCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function MyOrders() {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);

    const rowsPerPage = 6;
    const emptyRows = (loading || orders.length === 0) ? 1 : (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0);

    useEffect(() => {
        loadOrders();
    }, []);

    const loadOrders = () => {
        setLoading(true);
        getOrders()
            .then(setOrders)
            .catch(e => console.log(e))
            .finally(() => {
                setLoading(false);
            });
    }

    const details = useRef();

    const showDetails = (id) => {
        details.current.openDetails(id);
    }

    return (
        <Container>
            <Typography variant="h5">
                Mis pedidos
            </Typography>
            <Typography variant="subtitle1">
                Aquí puede ver sus pedidos realizados y el estado en que se encuentran
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <ThCell width={100} align="center">Nº de ticket</ThCell>
                            <ThCell align="center">Fecha</ThCell>
                            <ThCell align="center">Total</ThCell>
                            <ThCell align="center">Estado</ThCell>
                            {/*<ThCell align="center">Bono</ThCell>*/}
                            <ThCell width={40}></ThCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : orders
                        ).map(o => (
                            <StyledTableRow key={o.id}>
                                <TableCell align="center">{o.id}</TableCell>
                                <TableCell align="center">{dayjs(o.date).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                <TableCell align="center">{euro.format(o.total)}</TableCell>
                                <TableCell align="center">{TicketStatus[o.status].name}</TableCell>
                                {/*<TableCell align="center"></TableCell>*/}
                                <TableCell align="center">
                                    <Tooltip title="Ver detalle" placement="left">
                                        <IconButton color="inherit" onClick={() => showDetails(o.id)}>
                                            <ViewDetailsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    {loading ? (
                                        <CircularProgress />
                                    ) : (orders.length === 0 ? (
                                        <Typography variant="body1">
                                            No se han encontrado pedidos
                                            <IconButton onClick={() => loadOrders()} style={{ marginInline: 20 }}>
                                                <ReplayIcon />
                                            </IconButton>
                                        </Typography>
                                    ) : "")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                showFirstButton
                                showLastButton
                                colSpan={5}
                                count={orders.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, p) => setPage(p)}
                                labelDisplayedRows={({ from, to, count }) => `${from} – ${to} de ${count !== -1 ? count : `más de ${to}`}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <TicketDetails ref={details} />
        </Container>
    );
}