const apiUrl = '/api';
const adminUrl = '/admin';

export const API_URLS = {
    families: `${apiUrl}/families`,
    items: `${apiUrl}/items`,
    printers: `${apiUrl}/printers`,
    allergens: `${apiUrl}/allergens`,
    timetable: `${apiUrl}/timetable`,
    ticket: `${apiUrl}/ticket`,
    tickets: `${apiUrl}/tickets`,
    ticketLines: `${apiUrl}/ticketlines`,
    reports: `${apiUrl}/reports`,
    pages: `${apiUrl}/pages`,
    users: `${apiUrl}/users`,
    deliveryTypes: `${apiUrl}/deliverytypes`,
    scheduler: `${apiUrl}/scheduler`,
    checkout: `${apiUrl}/checkout`,
    voucher: `${apiUrl}/voucher`,
    vouchers: `${apiUrl}/vouchers`,
    orders: `${apiUrl}/orders`,
    streets: `${apiUrl}/streets`,
    reservation: `${apiUrl}/reservation`,
    promos: `${apiUrl}/promos`,
    types: `types`,
    sort: `sort`,
    opened: `opened`,
    signup: `signup`,
    signin: `signin`,
    signout: `signout`,
    recovery: `recovery`,
    time: `time`,
    pass: `password`,
};

export const ADMIN_URLS = {
    findUserByPhone: `${adminUrl}/users/phone`,
    tickets: `${adminUrl}/tickets`,
}

const orders = 'pedidos';
const policies = 'politicas';
export const FRONT_URLS = {
    activation: `activacion`,
    signedup: `resgistrado`,
    signup: `registro`,
    login: `login`,
    orders: `${orders}`,
    confirmed: `${orders}/confirmado`,
    denied: `${orders}/denegado`,
    reservation: `reservas`,
    menu: `carta`,
    restaurant: `restaurante`,
    profile: `perfil`,
    myorders: `mis-pedidos`,
    vouchers: `bonos`,
    privacy: `${policies}/privacidad`,
    cookies: `${policies}/cookies`,
    legal: `${policies}/aviso-legal`,
    sale: `${policies}/condiciones-venta`,
    reservationCanceled: `reserva-cancelada`,
    cancelReservation: `cancelar-reserva`,
    changePass: `cambio-password`,
    tpv: `tpv`
};