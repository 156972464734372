import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Link, Snackbar, Switch, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Link as RRLink, useNavigate } from "react-router-dom";
import { updateUser } from "../service/store.service";
import { FRONT_URLS } from "../config/url.config";
import StreetInput from "./StreetInput";

const InputField = ({ name, label, type = 'text', helperText, formValues, setFormValues, children }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error: false
            }
        });
    };

    return (
        <TextField
            name={name}
            label={label}
            type={type}
            size="medium"
            variant="outlined"
            fullWidth
            required
            value={formValues[name].value}
            onChange={handleChange}
            error={formValues[name].error}
            helperText={helperText || (formValues[name].error && formValues[name].errorMessage)}
            InputProps={{ endAdornment: (children) }}
        />
    )
}

export default function SignUp() {
    const [formValues, setFormValues] = useState({
        name: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        surname: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        phone: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        street: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        streetDetail: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        mail: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        password: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        repeatPassword: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        policy: {
            value: false,
            error: false,
            errorMessage: null
        },
    });

    const suForm = useRef(null);
    const navigate = useNavigate();

    const buttonSx = {
        width: { xs: '100%', md: '40%' }
        /*...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),*/
    };

    const validate = () => {
        const formEl = suForm.current;
        let newFormValues = { ...formValues }

        if (!formEl.checkValidity()) {
            for (let i = 0; i < formEl.length; i++) {
                const elem = formEl[i];
                if (elem.nodeName.toLowerCase() !== 'button') {
                    if (!elem.validity.valid) {
                        const nameEl = elem.name;
                        newFormValues = {
                            ...newFormValues,
                            [nameEl]: {
                                ...newFormValues[nameEl],
                                error: true,
                                errorMessage: elem.validationMessage
                            }
                        }
                    }
                }
            }
            setFormValues(newFormValues)
        } else {
            if (formValues.password.value !== formValues.repeatPassword.value) {
                setFormValues({
                    ...formValues,
                    repeatPassword: {
                        ...formValues.repeatPassword,
                        error: true,
                        errorMessage: 'Las contraseñas no coinciden'
                    }
                });
                return;
            }

            // Envia al back
            setSending(true);
            updateUser({
                name: formValues.name.value,
                surname: formValues.surname.value,
                mail: formValues.mail.value,
                pass: formValues.password.value,
                repeatedPass: formValues.repeatPassword.value,
                phone: formValues.phone.value,
                street: formValues.street.value,
                streetDetail: formValues.streetDetail.value,
            })
                .then((user) => {
                    //showSnack('Insertado correctamente. Siga las instrucciones recibidas para activar el usuario.', 'success');
                    // Ir a página de registrado.
                    navigate(`/${FRONT_URLS.signedup}`);
                })
                .catch((e) => {
                    console.log(e);
                    showSnack(e.response?.data ?? e.message, 'error');
                })
                .finally(() => {
                    setSending(false);
                });
        }
    };

    const showSnack = (msg, type) => {
        setSnackMsg(msg);
        setSnackType(type);
    }

    const switchValue = (e) => {
        setFormValues({
            ...formValues,
            policy: {
                ...formValues.policy,
                value: e.target.checked,
                error: false
            }
        });
    }

    const [showPassword, setShowPassword] = useState(false);
    const [sending, setSending] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [snackType, setSnackType] = useState('error');

    return (
        <Container>
            <Typography variant="h5" gutterBottom>
                Registro
            </Typography>
            <form ref={suForm} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <InputField
                            name="name"
                            label="Nombre"
                            formValues={formValues}
                            setFormValues={setFormValues}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputField name="surname" label="Apellidos" formValues={formValues} setFormValues={setFormValues} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputField name="phone" type="number" label="Teléfono" formValues={formValues} setFormValues={setFormValues} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StreetInput value={formValues.street.value}
                            setValue={(val) => {
                                setFormValues({
                                    ...formValues,
                                    street: {
                                        ...formValues.street,
                                        value: val,
                                        error: false
                                    }
                                });
                            }}
                            error={formValues.street.error}
                            helperText={formValues.street.error && formValues.street.errorMessage}
                        />
                        {/*<InputField name="street" label="Calle" formValues={formValues} setFormValues={setFormValues} helperText="Empiece a escribir y seleccione su calle de la lista" />*/}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputField name="streetDetail" label="Número, piso, puerta" formValues={formValues} setFormValues={setFormValues} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputField name="mail" type="email" label="Correo electrónico" formValues={formValues} setFormValues={setFormValues} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputField name="password" type={showPassword ? 'text' : 'password'} label="Contraseña" formValues={formValues} setFormValues={setFormValues}>
                            <IconButton
                                onMouseDown={() => setShowPassword(true)}
                                onMouseUp={() => setShowPassword(false)}
                                tabIndex={-1}
                            >
                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputField name="repeatPassword" type={showPassword ? 'text' : 'password'} label="Repetir contraseña" formValues={formValues} setFormValues={setFormValues}>
                            <IconButton
                                onMouseDown={() => setShowPassword(true)}
                                onMouseUp={() => setShowPassword(false)}
                                tabIndex={-1}
                            >
                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={formValues.policy.error}>
                            <FormControlLabel
                                control={<Switch name="policy" color="secondary" checked={formValues.policy.value} onChange={switchValue} />}
                                label={<div>He leído la <Link component={RRLink} target="_blank" to={`/${FRONT_URLS.privacy}`}>Política de privacidad</Link> y doy mi consentimiento para el tratamiento de datos</div>}
                                required
                            />
                            <FormHelperText>{formValues.policy.error && formValues.policy.errorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                variant="contained"
                                sx={buttonSx}
                                disabled={sending}
                                onClick={validate}
                            >
                                {sending ? "Enviando..." : "Registrar"}
                            </Button>
                            {sending && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        //color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={!!snackMsg}
                autoHideDuration={4000}
                onClose={() => setSnackMsg(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert variant="filled" elevation={6} onClose={() => setSnackMsg(null)} severity={snackType} sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </Container>
    )
}