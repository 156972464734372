import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Container, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { UserContext } from "../context/Context";
import { updatePass, updateUser } from "../service/store.service";
import StreetInput from "./StreetInput";

const InputField = ({ name, label, type = 'text', helperText, formValues, setFormValues, children }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error: false
            }
        });
    };

    return (
        <TextField
            name={name}
            label={label}
            type={type}
            size="medium"
            variant="outlined"
            fullWidth
            required
            value={formValues[name].value}
            onChange={handleChange}
            error={formValues[name].error}
            helperText={helperText || (formValues[name].error && formValues[name].errorMessage)}
            InputProps={{ endAdornment: (children) }}
        />
    )
}

export default function Profile() {
    const { user, updateSessionUser } = useContext(UserContext);

    const [showPassword, setShowPassword] = useState(false);
    const [sending, setSending] = useState(false);
    const [sendingPass, setSendingPass] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [snackType, setSnackType] = useState('error');

    const [formValues, setFormValues] = useState({
        name: {
            value: user.name,
            error: false,
            errorMessage: null
        },
        surname: {
            value: user.surname,
            error: false,
            errorMessage: null
        },
        phone: {
            value: user.phone,
            error: false,
            errorMessage: null
        },
        street: {
            value: user.street,
            error: false,
            errorMessage: null
        },
        streetDetail: {
            value: user.streetDetail,
            error: false,
            errorMessage: null
        },
        mail: {
            value: user.mail,
            error: false,
            errorMessage: null
        },
        password: {
            value: undefined,
            error: false,
            errorMessage: null
        },
        repeatPassword: {
            value: undefined,
            error: false,
            errorMessage: null
        },
    });

    const dataForm = useRef(null);
    const passForm = useRef(null);

    const buttonSx = {
        width: { xs: '100%', md: '40%' }
        /*...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),*/
    };

    const validatePassChange = () => {
        const formEl = passForm.current;
        let newFormValues = { ...formValues }

        if (!formEl.checkValidity()) {
            for (let i = 0; i < formEl.length; i++) {
                const elem = formEl[i];
                if (elem.nodeName.toLowerCase() !== 'button') {
                    if (!elem.validity.valid) {
                        const nameEl = elem.name;
                        newFormValues = {
                            ...newFormValues,
                            [nameEl]: {
                                ...newFormValues[nameEl],
                                error: true,
                                errorMessage: elem.validationMessage
                            }
                        }
                    }
                }
            }
            setFormValues(newFormValues);
        } else {
            if (formValues.password.value !== formValues.repeatPassword.value) {
                setFormValues({
                    ...formValues,
                    repeatPassword: {
                        ...formValues.repeatPassword,
                        error: true,
                        errorMessage: 'Las contraseñas no coinciden'
                    }
                });
                return;
            }

            setSendingPass(true);
            updatePass({
                id: user.id,
                mail: formValues.mail.value,
                pass: formValues.password.value,
                repeatedPass: formValues.repeatPassword.value,
            })
                .then(() => {
                    setFormValues({
                        ...formValues,
                        password: {
                            ...formValues.password,
                            value: ''
                        },
                        repeatPassword: {
                            ...formValues.repeatPassword,
                            value: ''
                        }
                    });
                    showSnack('Se ha actualizado la contraseña correctamente', 'success');
                })
                .catch((e) => {
                    console.log(e);
                    showSnack(e.response?.data ?? e.message, 'error');
                })
                .finally(() => {
                    setSendingPass(false);
                });
        }
    }

    const validate = () => {
        const formEl = dataForm.current;
        let newFormValues = { ...formValues }

        if (!formEl.checkValidity()) {
            for (let i = 0; i < formEl.length; i++) {
                const elem = formEl[i];
                if (elem.nodeName.toLowerCase() !== 'button') {
                    if (!elem.validity.valid) {
                        const nameEl = elem.name;
                        newFormValues = {
                            ...newFormValues,
                            [nameEl]: {
                                ...newFormValues[nameEl],
                                error: true,
                                errorMessage: elem.validationMessage
                            }
                        }
                    }
                }
            }
            setFormValues(newFormValues)
        } else {
            // Envia al back
            setSending(true);
            updateUser({
                id: user.id,
                mail: formValues.mail.value,
                name: formValues.name.value,
                surname: formValues.surname.value,
                phone: formValues.phone.value,
                street: formValues.street.value,
                streetDetail: formValues.streetDetail.value,
            })
                .then(() => {
                    updateSessionUser();
                    showSnack('Se han actualizado los datos correctamente', 'success');
                })
                .catch((e) => {
                    console.log(e);
                    showSnack(e.response?.data ?? e.message, 'error');
                })
                .finally(() => {
                    setSending(false);
                });
        }
    };

    const showSnack = (msg, type) => {
        setSnackMsg(msg);
        setSnackType(type);
    }

    return (
        <Container>
            <Typography variant="subtitle1" marginBottom={3}>
                Puede cambiar su contraseña y sus datos de contacto
            </Typography>
            <form ref={passForm} noValidate>
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Correo electrónico"
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={user.mail}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputField name="password" type={showPassword ? 'text' : 'password'} label="Contraseña" formValues={formValues} setFormValues={setFormValues}>
                            <IconButton
                                onMouseDown={() => setShowPassword(true)}
                                onTouchStart={() => setShowPassword(true)}
                                onMouseUp={() => setShowPassword(false)}
                                onTouchEnd={() => setShowPassword(false)}
                                tabIndex={-1}
                            >
                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputField name="repeatPassword" type={showPassword ? 'text' : 'password'} label="Repetir contraseña" formValues={formValues} setFormValues={setFormValues}>
                            <IconButton
                                onMouseDown={() => setShowPassword(true)}
                                onTouchStart={() => setShowPassword(true)}
                                onMouseUp={() => setShowPassword(false)}
                                onTouchEnd={() => setShowPassword(false)}
                                tabIndex={-1}
                            >
                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputField>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                variant="contained"
                                sx={buttonSx}
                                disabled={sendingPass}
                                onClick={validatePassChange}
                            >
                                {sendingPass ? "Enviando..." : "Cambiar contraseña"}
                            </Button>
                            {sendingPass && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <form ref={dataForm} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <InputField
                            name="name"
                            label="Nombre"
                            formValues={formValues}
                            setFormValues={setFormValues}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputField name="surname" label="Apellidos" formValues={formValues} setFormValues={setFormValues} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputField name="phone" type="number" label="Teléfono" formValues={formValues} setFormValues={setFormValues} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StreetInput value={formValues.street.value}
                            setValue={(val) => {
                                setFormValues({
                                    ...formValues,
                                    street: {
                                        ...formValues.street,
                                        value: val,
                                        error: false
                                    }
                                });
                            }}
                            error={formValues.street.error}
                            helperText={formValues.street.error && formValues.street.errorMessage}
                        />
                        {/*<InputField name="street" label="Calle" formValues={formValues} setFormValues={setFormValues} helperText="Empiece a escribir y seleccione su calle de la lista" />*/}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputField name="streetDetail" label="Número, piso, puerta" formValues={formValues} setFormValues={setFormValues} />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                variant="contained"
                                sx={buttonSx}
                                disabled={sending}
                                onClick={validate}
                            >
                                {sending ? "Enviando..." : "Actualizar datos"}
                            </Button>
                            {sending && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        //color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={!!snackMsg}
                autoHideDuration={4000}
                onClose={() => setSnackMsg(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert variant="filled" elevation={6} onClose={() => setSnackMsg(null)} severity={snackType} sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </Container >
    )
}