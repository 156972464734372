import { Box, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";
import './Animations.css';
import { useEffect, useState } from "react";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box position="relative">
                <div className="failure-checkmark">
                    <div className={`failure-icon ${animate ? "animate" : ''}`}>
                        <span className="icon-line line-left"></span>
                        <span className="icon-line line-right"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                    </div>
                </div>
                <Typography variant="h6" textAlign='center'>
                    La página que está buscando no es correcta o no existe.
                </Typography>
            </Box>
            <p>
                <i>{error?.statusText || error?.message}</i>
            </p>
        </div>

    );
}