import { Box, Typography } from "@mui/material";

export default function FoodLoader() {
    return (
        <Box textAlign='center'>
            <Box
                component="img"
                sx={{ width: { xs: 300, md: 500 } }}
                alt='Loader' src='/img/food-loader.gif'
            />
            <Typography variant="h5">Cargando...</Typography>
        </Box>
    );
}