import { Box, Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import './Animations.css';
import { useEffect, useState } from "react";
import { FRONT_URLS } from "../config/url.config";
import { Link as RRLink } from "react-router-dom";

export default function ReservationCanceled() {
    const [params] = useSearchParams();
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        params.has("error") ? (
            <Box position="relative">
                <div className="failure-checkmark">
                    <div className={`failure-icon ${animate ? "animate" : ''}`}>
                        <span className="icon-line line-left"></span>
                        <span className="icon-line line-right"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                    </div>
                </div>
                <Typography variant="h6" textAlign='center'>
                    Se ha producido un error al cancelar la reserva. <br/>
                    Inténtelo de nuevo y si no funciona, póngase en contacto con nosotros por teléfono al 971 66 11 17.
                </Typography>
            </Box>
        ) : (
            <Box position="relative">
                <div className="success-checkmark">
                    <div className={`check-icon ${animate ? " animate" : ''}`}>
                        <span className="icon-line line-tip"></span>
                        <span className="icon-line line-long"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                    </div>
                </div>
                <Typography variant="h6" textAlign='center'>
                    Se ha cancelado la reserva correctamente. <br/>
                    Si lo desea, puede volver a reservar a través del menú <Link component={RRLink} to={`/${FRONT_URLS.reservation}`}>Reservas</Link>.
                </Typography>
            </Box>
        )
    );
}