import CloseIcon from '@mui/icons-material/Close';
//import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from "react";
import { TicketContext } from '../context/Context';
import { changeTicketStatus, findTickets, recoverTicket } from '../service/store.service';
import { euro } from '../utils/Currency';
import { TicketStatus } from '../utils/TicketStatus';

const TicketDetail = ({ ticket, cancelTicket, reloadTicket }) => {
    //const [showDetails, setShowDetails] = useState(false);
    const [recovering, setRecovering] = useState(false);

    return (
        <Card elevation={2} style={{ marginBottom: 15, backgroundColor: dayjs(ticket.date).isSame(dayjs(), 'date') ? '' : '#ddd' }}>
            <CardContent>
                <Grid container>
                    <Grid item xs={2}>
                        <Typography variant="h6">
                            #{ticket.id} <Typography variant="caption">({ticket.origin})</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {/*<Badge
                            color="warning"
                            invisible={dayjs(ticket.date).isSame(dayjs(), 'date')}
                            badgeContent={"Dia anterior"}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >*/}
                        <Typography variant="h6">
                            {dayjs(ticket.date).format("DD/MM/YYYY HH:mm:ss")}
                        </Typography>
                        {/*</Badge>*/}
                    </Grid>
                    <Grid item xs={2} textAlign='right'>
                        <Chip label={TicketStatus[ticket.status].name} color={TicketStatus[ticket.status].color} />
                    </Grid>
                    {/*<Grid item xs={1} textAlign='right'>
                        <IconButton onClick={() => setShowDetails(!showDetails)}>
                            {showDetails ? (<ArrowUpIcon />) : (<ArrowDownIcon />)}
                        </IconButton>
                    </Grid>*/}
                    <Grid item xs={4}>
                        Nombre: {ticket.name}
                    </Grid>
                    <Grid item xs={4}>
                        Dirección: {ticket.address}
                    </Grid>
                    <Grid item xs={4}>
                        {ticket.scheduled ? `Programado: ${ticket.scheduled}` : ""}
                    </Grid>
                    <Grid item xs={4}>
                        Teléfono: {ticket.phone}
                    </Grid>
                    {/*showDetails && (
                        <Grid item xs={12}>
                            {ticket.ticketLines.map((line) => (
                                line.quantity + " " + line.item + " " + line.price
                            ))}
                        </Grid>
                    )*/}
                </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'space-between' }}>
                <Typography variant="h5">
                    Total: {euro.format(ticket.total)}
                </Typography>
                <Box>
                    <Button
                        onClick={() => {
                            setRecovering(true);
                            reloadTicket(ticket);
                        }}
                        variant='contained'
                        color='secondary'
                        style={{ marginInline: 10 }}
                        disabled={recovering}
                    >
                        Recuperar
                        {recovering && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    //color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>
                    <Button onClick={() => cancelTicket(ticket)} variant='contained' color='error'>Anular</Button>
                </Box>
            </CardActions>
        </Card>
    );
}

export default function TicketHistory({ open, onClose }) {
    const ticketContext = useContext(TicketContext);

    const [loading, setLoading] = useState();
    const [tickets, setTickets] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (open) {
            loadTickets();
        }
        // eslint-disable-next-line
    }, [open]);

    const handleClose = () => {
        onClose();
        setPage(0);
        setTickets([]);
    }

    const loadTickets = () => {
        setLoading(true);
        findTickets(null, null, dayjs().format("DD/MM/YYYY"), page)
            .then((ticketsRes) => {
                setTickets([...tickets, ...ticketsRes]);
                setPage(page + 1);
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    }

    const reloadTicket = async (ticket) => {
        await recoverTicket(ticket.id);
        await ticketContext.refreshTicket();
        handleClose();
    }

    const cancelTicket = (ticket) => {
        changeTicketStatus(ticket.id, TicketStatus.CANCELLED.id)
            .then(() => {
                setTickets(tickets.map((t) => {
                    if (t.id === ticket.id) t.status = TicketStatus.CANCELLED.id;
                    return t;
                }))
            })
            .catch(err => console.log(err));
    }

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth='xl'>
            <DialogTitle>
                Tickets
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                onScrollCapture={(event) => { // Infinite scroll
                    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
                    if (scrollTop > 0 && (scrollTop + clientHeight >= scrollHeight - 20)) {
                        if (!loading) loadTickets();
                    }
                }}
            >
                {!loading && tickets.length === 0 ? (
                    "No hay tickets en este dia"
                ) : (
                    tickets.map((ticket, index) => (
                        <TicketDetail key={index} ticket={ticket} cancelTicket={cancelTicket} reloadTicket={reloadTicket}></TicketDetail>
                    ))
                )}
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', margin: 2 }}>
                        <CircularProgress size={80} />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
}