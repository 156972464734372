import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import './Animations.css';
import { useEffect, useState } from "react";

export default function Activation() {
    const [params] = useSearchParams();
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        params.has("error") ? (
            <Box position="relative">
                <div className="failure-checkmark">
                    <div className={`failure-icon ${animate ? "animate" : ''}`}>
                        <span className="icon-line line-left"></span>
                        <span className="icon-line line-right"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                    </div>
                </div>
                <Typography variant="h6" textAlign='center'>
                    Se ha producido un error activando su usuario. <br/>
                    El link de activación sólo sirve una vez. Si no recuerda la contraseña, haga click en el enlace de recordar contraseña en el formulario de login.
                </Typography>
            </Box>
        ) : (
            <Box position="relative">
                <div className="success-checkmark">
                    <div className={`check-icon ${animate ? " animate" : ''}`}>
                        <span className="icon-line line-tip"></span>
                        <span className="icon-line line-long"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                    </div>
                </div>
                <Typography variant="h6" textAlign='center'>
                    ¡Usuario activado correctamente!
                </Typography>
            </Box>
        )
    );
}